sw.theme = {
    options: {
        slideshow: { // http://flickity.metafizzy.co/
            not: '', // Remove from slideshow selector
            autoPlay: null,
            imagesLoaded: true, // re-positions cells once their images have loaded
            wrapAround: true // infinite scrolling
        },
        productUpsellSlideshow: {
            minNrOfSlides: {
                checkoutLayout0: {
                    galleryLayout4: 8, // A
                    galleryLayout3: 7, // B
                    galleryLayout1: 5 // C
                },
                checkoutLayout1: {
                    galleryLayout4: 5,
                    galleryLayout3: 4,
                    galleryLayout1: 3
                },
                checkoutLayout2: {
                    galleryLayout4: 4,
                    galleryLayout3: 3,
                    galleryLayout1: 2
                }
            },
            autoPlay: null,
            wrapAround: true,
            cellAlign: 'left',
            groupCells: '100%'
        },
        gallery: {
            addedToCartFeedback: true,
            hasVisibleCartOnAddedToCart: {
                desktop: false,
                mobile: false
            }
        },
        productFilter: {
            maxFilterItems: 4,
            openFilterGroups: [0, 1],
            rangeSlider: {
                type: 'double',
                input_values_separator: ','
            }
        },
        siteHeader: {
            menuType: {
                region1: 'flyout-menu', // mega-dropdown-menu (3 levels) | flyout-menu (4 levels)
                region2: 'mega-dropdown-menu',
                region3: 'mega-dropdown-menu'
            },
            fixedHeader: {
                enabled: true,
                breakPoint: 980,
                pageScrolledDownPos: 100
            },
            hybridHeader: {
                isEnabled: false,
                breakpoint: 26
            }
        },
        readMore: {
            enabled: false,
            maxHeight: 100, // pixel height
            duration: 500, // how long the animation will run
            elem: '.category-description, .review-content',
            not: ''
        },
        layout: {
            mobileMenu: 'mobile-menu-with-left-push'
        }
    },

    /**
     * Initialize theme
     */
    init: function init() {
        sw.theme.setup();
    },

    /**
     * Setup theme
     */
    setup: function setup() {
        sw.theme.setupSlideshow();
        sw.theme.setupProductUpsellSlideshow();
        sw.theme.removeCLSFixClass();
        sw.theme.setupGallery();
        sw.theme.setupResponsiveVideoEmbed();
        sw.theme.setupProductFilter();
        sw.theme.setupFooter();
        sw.theme.setupForm();
        sw.theme.setupCart();
        sw.theme.setupSearch();
        sw.theme.setupHeader();
        sw.theme.setupLightbox();
        sw.theme.setupReadMore();
        sw.theme.setupOffCanvasNavigation();
        sw.theme.setupCheckout();
        sw.theme.setupLanguage();
        sw.theme.setupCurrency();
        sw.theme.setupVat();
        sw.theme.setupFullwidthBlock();
        sw.theme.setupProductImages();
        sw.theme.setUpVolumePricing();
        sw.theme.setUpStockStatus();
        $(document).on('productViewInfoUpdate', sw.theme.setupProductImages);
        $(document).on('productViewInfoUpdate', sw.theme.setUpStockStatus);
        $(document).on('productViewInfoUpdate', sw.theme.setupLightbox);
        $(document).on('productViewInfoUpdate', sw.theme.setupForm);
        $(document).on('checkoutDataUpdate', sw.theme.setupProductUpsellSlideshow);
        $(document).on('checkoutDataUpdate', sw.theme.setupForm);
        rtlContent();
    },

    /**
     * Setup slideshow
     */
    setupSlideshow: function setupSlideshow() {
        if ($.fn.flickity) {
            $('.slideshow-media').not(sw.theme.options.slideshow.not).each(function () {
                var options = $.extend({}, sw.theme.options.slideshow);

                if (options.autoPlay === null) {
                    options.autoPlay = $(this).data('sw-autoplay');
                    speed = parseInt($(this).data('sw-speed'));

                    if (options.autoPlay && speed > 0) {
                        options.autoPlay = speed * 1000;
                    } else if (options.autoPlay) {
                        options.autoPlay = 5000;
                    }
                }

                // Disable navigation if only one image
                var slideCount = $(this).find('.slide').length;
                if (typeof options.prevNextButtons === 'undefined' || options.prevNextButtons) {
                    options.prevNextButtons = slideCount > 1;
                }
                if (typeof options.pageDots === 'undefined' || options.pageDots) {
                    options.pageDots = slideCount > 1;
                }

                $(this).flickity(options).addClass('flickity-loaded').removeClass('cls-improving-class-trick');
            });

            if ($('.slideshow-media').length) {
                $('html').addClass('has-slideshow-media');
            }
        }
    },

    /**
     * Setup product upsell slideshow
     */
    setupProductUpsellSlideshow: function setupProductUpsellSlideshow() {

        var $gallery = $('.product-upsell-wrap .related-products-gallery'),
            nrOfGalleryItems = $gallery.find('.gallery-item').length;

        var options = sw.theme.options.productUpsellSlideshow;

        options.groupCells = '80%'; // Add multiple cells to the selected slide

        if (options.autoplaySpeed === null) {
            speed = parseInt($gallery.data('sw-speed'));
            if (speed > 0) {
                options.autoplaySpeed = speed * 1000;
            } else {
                options.autoplaySpeed = 5000;
            }
        }

        // CHECKOUT-LAYOUT 0
        if ($('html').hasClass('theme-checkout-layout-0')) {
            if ($gallery.hasClass('gallery-layout-1') && nrOfGalleryItems >= options.minNrOfSlides.checkoutLayout0.galleryLayout1 || $gallery.hasClass('gallery-layout-3') && nrOfGalleryItems >= options.minNrOfSlides.checkoutLayout0.galleryLayout3 || $gallery.hasClass('gallery-layout-4') && nrOfGalleryItems >= options.minNrOfSlides.checkoutLayout0.galleryLayout4) {
                $gallery.addClass('product-upsell-slideshow').flickity(options);
                $('.gallery-item').setAllToMaxHeight();
            }
            // CHECKOUT-LAYOUT 1
        } else if ($('html').hasClass('theme-checkout-layout-1')) {
            if ($gallery.hasClass('gallery-layout-1') && nrOfGalleryItems >= options.minNrOfSlides.checkoutLayout1.galleryLayout1 || $gallery.hasClass('gallery-layout-3') && nrOfGalleryItems >= options.minNrOfSlides.checkoutLayout1.galleryLayout3 || $gallery.hasClass('gallery-layout-4') && nrOfGalleryItems >= options.minNrOfSlides.checkoutLayout1.galleryLayout4) {
                $gallery.addClass('product-upsell-slideshow').flickity(options);
                $('.gallery-item').setAllToMaxHeight();
            }
            // CHECKOUT-LAYOUT 2
        } else if ($('html').hasClass('theme-checkout-layout-2')) {
            if ($gallery.hasClass('gallery-layout-1') && nrOfGalleryItems >= options.minNrOfSlides.checkoutLayout2.galleryLayout1 || $gallery.hasClass('gallery-layout-3') && nrOfGalleryItems >= options.minNrOfSlides.checkoutLayout2.galleryLayout3 || $gallery.hasClass('gallery-layout-4') && nrOfGalleryItems >= options.minNrOfSlides.checkoutLayout2.galleryLayout4) {
                $gallery.addClass('product-upsell-slideshow').flickity(options);
                $('.gallery-item').setAllToMaxHeight();
            }
        }
    },

    /**
     * Remove CLS fix-class from sliders
     */
    removeCLSFixClass: function removeCLSFixClass() {
        $('.slideshow-media').each(function () {
            $(this).removeClass('cls-improving-class-trick');
        });
    },

    /**
     * Setup gallery
     */
    setupGallery: function setupGallery() {
        var options = sw.theme.options.gallery;

        // Show added to cart feedback
        if (options.addedToCartFeedback) {
            $(".products-gallery .gallery-item, .related-products-gallery .gallery-item").each(function () {
                $(this).append($('<div class="added-to-cart-feedback" />').append($('<div class="feedback-icon" />')));
            });

            $(".gallery-item").on("addedToCart", function () {
                if (options.hasVisibleCartOnAddedToCart.desktop) {
                    $('html.desktop').addClass('has-visible-off-canvas-cart');
                }
                if (options.hasVisibleCartOnAddedToCart.mobile) {
                    $('html.mobile').addClass('has-visible-off-canvas-cart');
                }
                $feedback = $(this).find('.added-to-cart-feedback');
                $feedback.css({ opacity: "0" }).removeClass('show').stop(true, true).addClass('show').animate({ opacity: "1" });
                $(this).mouseout(function () {
                    $feedback.delay(1500).animate({ opacity: "0" }, 400, 'swing', function () {
                        return $feedback.removeClass('show');
                    });
                });
            });
        } else {
            $(".gallery-item").on("addedToCart", function () {
                if (options.hasVisibleCartOnAddedToCart.desktop) {
                    $('html.desktop').addClass('has-visible-off-canvas-cart');
                }
                if (options.hasVisibleCartOnAddedToCart.mobile) {
                    $('html.mobile').addClass('has-visible-off-canvas-cart');
                }
            });
        }
    },

    /**
     * Setup embedded videos to be responsive.
     */
    setupResponsiveVideoEmbed: function setupResponsiveVideoEmbed() {
        $('iframe[src*="youtube.com"], iframe[src*="player.vimeo.com"]').each(function () {
            $(this).removeAttr('width').removeAttr('height').wrap('<div class="video">');
        });
    },

    /**
     * Setup product filter
     */
    setupProductFilter: function setupProductFilter() {
        var options = sw.theme.options.productFilter;

        // Create range sliders
        sw.theme.createRangeSlider();

        // Truncate long lists of filtering values
        $('.filter-items').each(function (index) {
            var $truncatedValues = $(this).find('.filter-item:gt(' + (options.maxFilterItems - 1) + ')');
            $truncatedValues.hide();

            // Show truncation link
            if ($truncatedValues.length) {
                $(this).find('.see-more-filter-items').show();
            }
        });

        $('#search-filter').on('click', '.see-more-filter-items', function (event) {
            event.preventDefault();
            $(this).hide().closest('.filter-items').find('.filter-item:hidden, .see-less-filter-items').show();
        });

        $('#search-filter').on('click', '.see-less-filter-items', function (event) {
            event.preventDefault();
            var $filterItems = $(this).closest('.filter-items');
            $(this).hide();
            $filterItems.find('.filter-item:gt(' + (options.maxFilterItems - 1) + ')').hide();
            $filterItems.find('.see-more-filter-items').show();
        });

        // Open selected filtergroups on desktop
        if (sw.atMedia.minWidth(800) && $('html').hasClass('product-list-block-with-filters-aside') === true) {
            $('.filter-group').filter(function (index) {
                return $.inArray(index, options.openFilterGroups) > -1;
            }).toggleClass('filter-group-open filter-group-closed');
        }

        // Toggle active filters
        $('#search-filter').on('click', '.toggle-active-filters', function () {
            var $block = $(this).closest('.block');
            $block.toggleClass('filter-menu-show-filters');
            $block.removeClass('filter-menu-show-sort-order');
            $(this).toggleClass('current-filter-menu-item').siblings().removeClass('current-filter-menu-item');
        });

        // Toggle sort order
        $('#search-filter').on('click', '.toggle-sort-order', function () {
            var $block = $(this).closest('.block');
            $block.toggleClass('filter-menu-show-sort-order');
            $block.removeClass('filter-menu-show-filters');
            $(this).toggleClass('current-filter-menu-item').siblings().removeClass('current-filter-menu-item');
        });

        // Close open filtergroup on click outside
        if ($('.product-list-block-with-filters-aside').length < 1) {
            sw.theme.toggleOnClickOutside('.filter-group-open', 'filter-group-open filter-group-closed');
        }
    },

    /**
     * Create range slider
     */
    createRangeSlider: function createRangeSlider() {
        var options = sw.theme.options.productFilter;
        $('.filter-type-range').each(function () {
            var $range = $(this);
            var settings = {
                onFinish: function onFinish(data) {
                    var isNewFilterValue = sw.productFilter.isPriceRangeChanged($range.prop("value")),
                        parameters;
                    if (isNewFilterValue) {
                        sw.productFilter.addToActiveFilters($range.data('filterItemTitle', data.from + '-' + data.to));
                    } else {
                        $('.active-filter-type-range').click();
                    }
                    parameters = sw.productFilter.updateQueryParameters(isNewFilterValue);
                    sw.productFilter.updateProductListAndFilters(parameters);
                }
            };

            $range.ionRangeSlider($.extend(settings, options.rangeSlider));
        });
    },

    /**
     * Reset range slider
     */
    resetRangeSlider: function resetRangeSlider(filterItemId) {
        var $range = $('#' + filterItemId),
            rangeSlider = $range.data("ionRangeSlider");

        rangeSlider.update({
            from: parseFloat($range.data('min')),
            to: parseFloat($range.data('max'))
        });
    },

    /**
     * Setup Footer
     */
    setupFooter: function setupFooter() {
        if ($('html').hasClass('has-sidebar')) {
            $(window).on('load resize scroll', function () {
                var contentHeight = $('#site-header').outerHeight(true) + $('#content').outerHeight(true) + $('#site-footer').outerHeight(true),
                    containerHeight = $('#page-container').outerHeight(true);

                if (contentHeight < containerHeight) {
                    var diffHeight = containerHeight - contentHeight;
                    if (!$('#sticky-footer-push').length) {
                        $('#site-footer').before('<div id="sticky-footer-push"></div>');
                    }

                    $('#sticky-footer-push').height(diffHeight);
                }
            });
        }
    },

    /**
     * Setup Form
     */
    setupForm: function setupForm() {
        $formFields = '.text-type input, .email-type  input, .email-type input, .number-type input, .password-type input, .textarea-type textarea, .select-type select';
        $($formFields).each(function () {
            if (this.value.length > 0) {
                $(this).closest('.field-wrap').addClass('has-field-content');
            }
        });

        $(document).on('keyup', $formFields, function () {
            if (this.value.length > 0) {
                $(this).closest('.field-wrap').addClass('has-field-content');
            } else {
                $(this).closest('.field-wrap').removeClass('has-field-content');
            }
        });
    },

    /**
     * Setup cart
     */
    setupCart: function setupCart() {
        var $cart = $('#site-header .cart-block');

        $cart.on("cartItemAdded", function () {
            $cart = $(this);
            $cart.addClass('cart-item-added');
            setTimeout(function () {
                $cart.removeClass('cart-item-added');
            }, 500);
        });

        if ($cart.hasClass('cart-block-with-off-canvas')) {
            // Off canvas cart
            var $offCanvasNav = $('<div class="off-canvas-cart" />');

            // Append off canvas cart
            $clonedCart = $cart.first().clone();
            $('body').append($offCanvasNav.append($clonedCart));

            // Show cart content
            $(document).on('click', '.cart-header', function (event) {
                // Preventing ghost click
                event.stopPropagation();
                event.preventDefault();
                $('html').toggleClass('has-visible-off-canvas-cart');
            });

            // Hide cart content
            $(document).on('click touchstart', '.cart-content-close, .has-visible-off-canvas-cart #page-container', function (event) {
                event.stopPropagation();
                event.preventDefault();
                $('html').removeClass('has-visible-off-canvas-cart');
            });
        } else if ($cart.hasClass('cart-block-with-modal')) {
            // Modal cart
            var $modalWin = $('<div class="modal-cart" />'),
                $modalContent = $('<div class="modal-cart-content" />'),
                $modalClose = $('<a href="#" class="modal-cart-close cart-content-close" />');

            // Append modal cart
            $clonedCart = $cart.first().clone();
            $modalContent.append($clonedCart);
            $modalContent.append($modalClose);
            $('body').append($modalWin.append($modalContent));

            // Show modal
            $(document).on('click touchend', '.cart-header', function (event) {
                // Preventing ghost click
                event.stopPropagation();
                event.preventDefault();
                $('html').toggleClass('has-visible-modal-cart');
            });

            // Hide modal
            $(document).on('click touchend', '.cart-content-close, .has-visible-modal-cart #page-container', function (event) {
                event.stopPropagation();
                event.preventDefault();
                $('html').removeClass('has-visible-modal-cart');
            });

            // Hide modal on click outside
            $(document).on('click', '', function (event) {
                if (!$modalContent.is(event.target) && $modalContent.has(event.target).length === 0) {
                    $('html').removeClass('has-visible-modal-cart');
                }
            });
        }
    },

    /**
     * Show cart content.
     */
    showCartContent: function showCartContent(event) {
        if (event) {
            event.preventDefault();
        }
        $(this).closest('.cart-block').toggleClass('show-cart-content');
        $('html').addClass('has-visible-cart-content');
    },

    /**
     * Hide cart content.
     */
    hideCartContent: function hideCartContent(event) {
        if (event) {
            event.preventDefault();
        }
        $(this).closest('.cart-block').removeClass('show-cart-content');
        $('html').removeClass('has-visible-cart-content');
    },

    /**
     * Setup search
     */
    setupSearch: function setupSearch() {
        var $fullwidthSearch = $('.header-items .search-block-with-fullwidth'),
            $expandSearch = $('.header-items .search-block-with-expand');

        if ($expandSearch.length) {
            var text = $expandSearch.find('h3').text();
            $expandSearch.find('label').text(text);

            $expandSearch.find('h3').click(function () {
                $(this).closest('.block').addClass('expand-search');
            });

            setTimeout(function () {
                $expandSearch.find('input').focus();
            }, 2000);

            sw.theme.hideOnClickOutside('.search-block-with-expand', 'expand-search');
        }

        if ($fullwidthSearch.length) {
            var $searchField = $('.header-items .search-block-with-fullwidth'),
                searchTitle = $('.header-items .search-block-with-fullwidth h3').text();

            $toggleSearchButton = '<div class="toggle-fullwidth-search open-fullwidth-search"><span>' + searchTitle + '</span></div>';
            $searchField.after($toggleSearchButton).detach().insertBefore('.header-items');

            $closeSearchButton = '<button type="button" class="toggle-fullwidth-search  close-fullwidth-search"><span></span></button>';
            $('#site-header .search-block-with-fullwidth form').append($closeSearchButton);

            $(document).on('click', '.toggle-fullwidth-search', function (event) {
                if (event) {
                    event.preventDefault();
                }
                $('#site-header .search-block-with-fullwidth').toggleClass('show-search');
                $('html').toggleClass('has-open-fullwidth-search');
                $('.desktop .search-block-with-fullwidth input').focus();
            });
        }
    },

    /**
     * Setup header
     */
    setupHeader: function setupHeader() {
        applyHeaderOptionsWhenInDesignMode();
        var options = sw.theme.options.siteHeader,
            $header = $('#site-header');

        // Add menutype
        $('html:not(.theme-color-scheme-0) .header-region-1').addClass('menu-block-with-' + options.menuType.region1);
        $('html:not(.theme-color-scheme-0) .header-region-2').addClass('menu-block-with-' + options.menuType.region2);
        $('html:not(.theme-color-scheme-0) .header-region-3').addClass('menu-block-with-' + options.menuType.region3);

        if (options.hybridHeader.isEnabled) {
            sw.theme.setupHybridHeader(options, $header);

            return;
        }

        if (options.fixedHeader.enabled) {
            $(window).on('load resize', function () {
                if ($(document).width() < options.fixedHeader.breakPoint) {
                    $('html').addClass('lt-fixed-breakpoint');
                    $('html').addClass('has-static-site-header');

                    $('html').removeClass('has-fixed-site-header');
                    $header.removeClass('fixed-site-header');
                } else {
                    $('html').removeClass('lt-fixed-breakpoint');
                    $('html').removeClass('has-static-site-header');

                    $('html').addClass('has-fixed-site-header');
                    $header.addClass('fixed-site-header');
                }
            });
        }

        if (options.fixedHeader.enabled && $(document).width() > options.fixedHeader.breakPoint) {
            sw.theme.instantiatePhantomHeader($header);

            // Adds classes when page is scrolled down
            $(window).scroll(function () {
                var currentScrollPos = $(this).scrollTop();
                if (currentScrollPos >= options.fixedHeader.pageScrolledDownPos) {
                    $('html').addClass("page-scrolled-down");
                    $header.addClass('site-header-with-page-scrolled-down');
                } else {
                    $('html').removeClass("page-scrolled-down");
                    $header.removeClass('site-header-with-page-scrolled-down');
                }
            });
        }
    },
    setupHybridHeader: function setupHybridHeader(options, $header) {
        $('html').addClass('has-hybrid-site-header');
        $(window).scroll(function () {
            var breakpoint = options.hybridHeader.breakpoint = $('.header-item-1').outerHeight();
            var currentScrollPosition = $(this).scrollTop();
            var siteHeaderHeight = $('.site-header').outerHeight();
            var offset = 50 - $('.header-item-1').outerHeight();

            if (currentScrollPosition >= breakpoint) {
                $('html').addClass('has-fixed-site-header');
                $header.addClass('fixed-site-header');
                $("#content").css('margin-top', siteHeaderHeight);
                $('.fixed-site-header').css('top', -$('.header-item-1').outerHeight());
                if ($('html').hasClass('admin-user-logged-in') && !$('html').hasClass('is-preview')) {
                    $('.fixed-site-header').css('top', offset + 'px');
                }
            } else {
                $('html').removeClass('has-fixed-site-header');
                $header.removeClass('fixed-site-header');
                $("#content").css('margin-top', '0');
                $('.fixed-site-header').css('top', '0');
                if ($('html').hasClass('admin-user-logged-in') && !$('html').hasClass('is-preview')) {
                    $('.fixed-site-header').css('top', '50px');
                }
            }
        });
    },
    instantiatePhantomHeader: function instantiatePhantomHeader($header) {
        var headerHeight = $header.outerHeight();

        // Replace header with phantom element when changing from static to fixed position
        $headerPhantom = $('<div id="site-header-phantom"/>');

        // Fixes safari height() issue
        setTimeout(function () {
            headerHeight = $header.outerHeight();
            $headerPhantom.height(headerHeight);
        }, 100);

        if ($('html').hasClass('has-sidebar')) {
            $('#site-header').before($headerPhantom);
        } else {
            $('#main-content').before($headerPhantom);
        }

        // Fixes Sticky header & internal links
        if ($('.tb-desktop-menu:visible').length) {
            headerHeight = headerHeight + $('.tb-desktop-menu').height();
        }
        $('<style>*:target:before{content:"";display:block; height:' + headerHeight + 'px; margin:-' + headerHeight + 'px 0 0; }</style>').appendTo('head');
    },

    /**
     * Setup lightbox
     */
    setupLightbox: function setupLightbox() {
        var isNonDesignTheme = $('html:not(.theme-color-scheme-0)').length,
            usePhotoswipe = $('html').hasClass('product-page-with-photoswipe');

        if (isNonDesignTheme || usePhotoswipe) {
            var items = []; // PhotoSwipe items array

            $('#product-page').find('.pswp-wrap').remove(); // Removes old lightbox on productViewInfoUpdate()

            $('#product-page').append($('<div class="pswp-wrap" />').load("/themes/william/templates/photoswipe.html", function () {

                $('.product-media-item img').each(function (index, value) {
                    $(this).attr('data-index', index);
                    items.push({
                        src: $(this).attr('data-large-size'),
                        w: $(this).attr('data-large-size-width'),
                        h: $(this).attr('data-large-size-height')
                    });
                });

                // PhotoSwipe element
                var pswpElement = document.querySelectorAll('.pswp')[0];

                // Open lightbox
                $(document).on('click', '.product-media-item', function (event) {
                    event.preventDefault();

                    var $img = $(this).find('img');
                    if ($img.attr('data-large-size-width') === '' || $img.attr('data-large-size-height') === '') {
                        return false;
                    }

                    // Mobile list-layout uses pinch to zoom
                    if ($('html').hasClass('mobile') && $('.product-media').hasClass('product-gallery-layout-1')) {
                        return false;
                    }

                    var options = {
                        bgOpacity: 0.92,
                        index: parseInt($img.data('index')) // Start at index
                    };

                    // Initialize and open PhotoSwipe
                    var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
                    gallery.listen('gettingData', function (index, item) {
                        var $img = $('.product-media-item img[data-index="' + index + '"]', '#main-content');
                        var largeSize = $img.attr('data-large-size');
                        var largeSizeWidth = $img.attr('data-large-size-width');
                        var largeSizeHeight = $img.attr('data-large-size-height');
                        if (item.src !== largeSize) {
                            item.src = largeSize;
                        }
                        if (item.w !== largeSizeWidth) {
                            item.w = largeSizeWidth;
                        }
                        if (item.h !== largeSizeHeight) {
                            item.h = largeSizeHeight;
                        }
                    });
                    gallery.init();
                });
            }));
        }
    },

    /**
     * Setup Read more / Read less
     */
    setupReadMore: function setupReadMore() {
        var options = sw.theme.options.readMore;
        if (options.enabled) {
            $(options.elem).not(options.not).each(function () {
                var $readMoreReadLess = $('<div class="read-more-read-less read-more" />'),
                    $readMoreLink = $('<a href="#" />'),
                    $elem = $(this),
                    origHeight = $elem.outerHeight();

                if (origHeight > options.maxHeight) {
                    $elem.css('overflow', 'hidden').height(options.maxHeight);
                    $readMoreReadLess.append($readMoreLink);
                    $elem.after($readMoreReadLess.append($readMoreReadLess));
                }

                $(document).on('click', '.read-more-read-less', function (event) {
                    event.preventDefault();

                    if ($readMoreReadLess.hasClass('read-more')) {
                        $elem.css('overflow', '').animate({ height: origHeight + 'px' }, options.duration, function () {
                            $elem.height('100%');
                            $elem.css('overflow', 'auto');
                        });
                        $readMoreReadLess.toggleClass('read-more read-less');
                    } else {
                        $elem.css('overflow', 'hidden').animate({ height: options.maxHeight + 'px' }, options.duration, function () {
                            $readMoreReadLess.toggleClass('read-more read-less');
                        });

                        var y = $(window).scrollTop();
                        $("html, body").animate({ scrollTop: y - origHeight }, options.duration);
                    }
                });
            });
        }
    },

    /**
     * Setup navigation
     */
    setupOffCanvasNavigation: function setupOffCanvasNavigation() {
        var layout = sw.theme.options.layout.mobileMenu,
            isLeftSlide = layout == 'mobile-menu-with-left-slide' ? true : false;

        $('html').addClass(layout);
        // Site navigation on smaller screens
        $('.header-items').before('<span id="mobile-menu"><span></span></span>');

        var $headerMenu = $('#site-header .menu-block'),
            hasHeaderMenu = $headerMenu.length,
            $sidebarMenu = $('#content-sidebar .menu-block'),
            hasSidebarMenu = $sidebarMenu.length,
            $mobileBlocks = $('#mobile-nav .block'),
            hasMobileBlocks = $mobileBlocks.length,
            $offCanvasNav = $('<div class="off-canvas-nav" />'),
            $closeBtn = $('<div class="off-canvas-close" />');

        if (isLeftSlide) {
            $offCanvasNav.append($closeBtn);
        }

        // Blocks from mobile region
        if (hasMobileBlocks) {
            var $clonedMobileBlocks = $mobileBlocks.clone();
            $('body').append($offCanvasNav.append($clonedMobileBlocks));
        } else {
            // Menu blocks from header regions
            if (hasHeaderMenu) {
                var $clonedHeaderMenu = $headerMenu.clone();
                $('body').append($offCanvasNav.append($clonedHeaderMenu));
            }
            // Menu blocks from sidebar region
            if (hasSidebarMenu) {
                var $clonedSidebarMenu = $sidebarMenu.clone();
                if ($('off-canvas-nav').length) {
                    $offCanvasNav.append($clonedSidebarMenu);
                } else {
                    $('body').append($offCanvasNav.append($clonedSidebarMenu));
                }
            }
        }

        // Show/hide off canvas navigation
        $(document).on('click touchend', '#mobile-menu, .has-visible-off-canvas-nav #page-container, .off-canvas-close', function (event) {
            event.stopPropagation();
            event.preventDefault();
            $('html').toggleClass('has-visible-off-canvas-nav');
            if ($('.autocomplete').autocomplete() && $('.autocomplete').autocomplete().visible) {
                $('.autocomplete').autocomplete().killSuggestions();
            }
        });

        // Add class to matching blocks in header when found in mobile blocks
        if ($('#mobile-nav').length) {
            var classList = $('#mobile-nav').attr('class').split(/\s+/);
            $.each(classList, function (index, item) {
                if (item.match("^has-")) {
                    var blockClass = '.' + item.replace('has-', '');
                    $('.header-items ' + blockClass).addClass('in-mobile-region');
                }
            });
        }

        sw.setupUrlValueRedirectorSelects();
    },

    /**
     * Setup checkout
     */
    setupCheckout: function setupCheckout() {
        // Fix jQuery toogle() visibility of flexbox
        $(document).on('click', '.toggle.discount-code a[data-toggle]', function (event) {
            if ($('.discount-code-content:visible').length) {
                $('.discount-code-content').addClass('flex').removeAttr('style');
            }
        });

        // Open checkout terms in lightbox
        $(document).on('click', '#terms-link', function (event) {
            event.preventDefault();
            $(this).swModal({
                type: 'iframe',
                max_width: 600
            });
        });

        if ($('#terms-page').length && sw.theme.isInIframe()) {
            $('html').addClass('is-in-iframe');
        }
    },

    /**
     * Setup language
     */
    setupLanguage: function setupLanguage() {
        $('.language-block').each(function () {
            if ($(this).hasClass('language-block-with-flags')) {
                $(this).hide();
                $languages = $('<div class="block select-language-flags" />');
                $('select option', this).each(function () {
                    var href = $(this).val(),
                        langCode = href.substr(26, 2),
                        title = $(this).text(),
                        addClass = '';
                    if (langCode == currentLangCode) {
                        addClass = ' current-language';
                    }
                    $('<a />', {
                        href: href,
                        title: title,
                        html: '<span class="language-icon language-icon-' + langCode + '"></span><span class="language-code">' + langCode + '</span>',
                        class: 'language language-' + langCode + addClass,
                        rel: 'nofollow'
                    }).appendTo($languages);
                });

                $(this).after($languages);
            } else if ($(this).hasClass('language-block-with-dropdown-flags')) {
                $(this).hide();
                var $select = $('<div class="block select-language-dropdown-flags" />'),
                    $menu = $('<div class="select-language-menu" />'),
                    $button = $('<a />', {
                    href: '#',
                    html: '<span class="language-code">' + currentLangCode + '</span><span class="language-icon language-icon-' + currentLangCode + '"></span>',
                    class: 'select-language-button'
                }),
                    $dropdown = $('<div class="select-language-dropdown" />');

                $('select option', this).each(function () {
                    var href = $(this).val(),
                        langCode = href.substr(26, 2),
                        title = $(this).text(),
                        addClass = '';
                    if (langCode == currentLangCode) {
                        addClass = ' current-language';
                    }
                    $('<a />', {
                        href: href,
                        title: title,
                        html: '<span class="language-code">' + langCode + '</span><span class="language-icon language-icon-' + langCode + '"></span>',
                        class: 'language language-' + langCode + addClass,
                        rel: 'nofollow'
                    }).appendTo($dropdown);
                });

                $(this).after($select.append($menu.append($button).append($dropdown)));

                $button.click(function (event) {
                    event.preventDefault();
                    $(this).parent().toggleClass('open-language-dropdown');
                });
            }
        });
    },

    /**
     * Setup currency
     */
    setupCurrency: function setupCurrency() {
        $('.currency-block').each(function () {
            var currentCurrencyCode = $('option:selected', this).text();

            if ($(this).hasClass('currency-block-with-flags')) {
                $(this).hide();
                $currency = $('<div class="block select-currency-flags" />');
                $('select option', this).each(function () {
                    var href = $(this).val(),
                        currencyCode = href.substr(30, 3),
                        title = $(this).text(),
                        addClass = '';
                    if (currencyCode == currentCurrencyCode) {
                        addClass = ' current-currency';
                    }
                    $('<a />', {
                        href: href,
                        title: title,
                        html: '<span class="currency-icon currency-icon-' + currencyCode.toLowerCase() + '"></span><span class="currency-code">' + currencyCode + '</span>',
                        class: 'currency currency-' + currencyCode + addClass,
                        rel: 'nofollow'
                    }).appendTo($currency);
                });

                $(this).after($currency);
            } else if ($(this).hasClass('currency-block-with-dropdown-flags')) {
                $(this).hide();
                var $select = $('<div class="block select-currency-dropdown-flags" />'),
                    $menu = $('<div class="select-currency-menu" />'),
                    $button = $('<a />', {
                    href: '#',
                    html: '<span class="currency-code">' + currentCurrencyCode + '</span><span class="currency-icon currency-icon-' + currentCurrencyCode.toLowerCase() + '"></span>',
                    class: 'select-currency-button'
                }),
                    $dropdown = $('<div class="select-currency-dropdown" />');

                $('select option', this).each(function () {
                    var href = $(this).val(),
                        currencyCode = href.substr(30, 3),
                        title = $(this).text(),
                        addClass = '';
                    if (currencyCode == currentCurrencyCode) {
                        addClass = ' current-currency';
                    }
                    $('<a />', {
                        href: href,
                        title: title,
                        html: '<span class="currency-code">' + currencyCode + '</span><span class="currency-icon currency-icon-' + currencyCode.toLowerCase() + '"></span>',
                        class: 'currency currency-' + currencyCode + addClass,
                        rel: 'nofollow'
                    }).appendTo($dropdown);
                });

                $(this).after($select.append($menu.append($button).append($dropdown)));

                $button.click(function (event) {
                    event.preventDefault();
                    $(this).parent().toggleClass('open-currency-dropdown');
                });
            }
        });
    },

    /**
     * Vat selector block
     */
    setupVat: function setupVat() {
        // Wrap text with span
        $('.vat-selector-block').each(function () {
            var link = $(this).find('a').detach();
            $(this).find('p').wrapInner('<span></span>').append(link);
            $(this).fadeIn();
        });
    },

    /**
     * Fullwidth block
     */
    setupFullwidthBlock: function setupFullwidthBlock() {
        sw.theme.origImgFile($('.block-fullwidth img'));
    },

    /**
     * Setup Product Images
     */
    setupProductImages: function setupProductImages() {
        if ($('.product-media-item:not(.featured)').length < 1) {
            return;
        }

        $('.product-media-item:not(.featured)').hide();

        $('.product-media').addClass('product-gallery-layout-3');

        var $productGallery = $('<div id="product-gallery" class="product-gallery" />'),
            $productGalleryItems = $('<div class="product-gallery-items" />');

        $('.product-media-item:not(.featured)').each(function () {
            var $figure = $('<figure  class="product-gallery-item" />');
            var $figureContent = $('<div class="figure-content" />');
            var a = $(this).clone();

            if (a.hasClass('featured')) {
                $figure.addClass('current-product-gallery-item');
            }

            $figureContent.appendTo($figure);
            $figureContent.append(a);
            $figure.appendTo($productGalleryItems);
        });

        $('.product-media-item:not(.featured)').remove();
        $productGallery.append($productGalleryItems);
        $('.product-media').append($productGallery);
        $('.product-media-item').fadeIn();

        // Product gallery layout
        var productGalleryLayout = '<div class="product-gallery-layout"><a href="#" data-layout="3" class="product-gallery-layout-3 current"></a><a href="#" data-layout="1" class="product-gallery-layout-1"></a></div>';
        $productGalleryItems.before($(productGalleryLayout).addClass('product-gallery-layout-first'));
        $productGalleryItems.after($(productGalleryLayout).addClass('product-gallery-layout-last'));

        // Change gallery layout
        $(document).on('click touchstart', '.product-gallery-layout a', function (event) {
            event.stopPropagation();
            event.preventDefault();
            var selectedLayout = $(this).data('layout');
            if ($(this).hasClass('current')) {
                return;
            }

            $('.product-gallery-layout a').removeClass('current');
            $('.product-gallery-layout-' + selectedLayout).addClass('current');

            $('.product-media').removeClass(function (index, css) {
                return (css.match(/\bproduct-gallery-layout-\S+/g) || []).join(' ');
            }).addClass('product-gallery-layout-' + selectedLayout);

            // Scroll to product gallery start
            if ($(this).parent().hasClass('product-gallery-layout-last')) {
                var headerHeight = 0;
                if (sw.theme.options.siteHeader.fixedHeader.enabled) {
                    headerHeight = $('#site-header').outerHeight();
                }
                $('body').animate({ scrollTop: Math.abs($('#product-gallery').offset().top - headerHeight) }, 500);
            }
        });
    },

    /**
     * Setup product volume pricing
     */
    setUpVolumePricing: function setUpVolumePricing() {
        $(document).on('click', '.volume-pricing-header', function () {
            $(this).closest('.volume-pricing').toggleClass('has-visible-volume-pricing-content');
        });
    },

    /**
     * Setup product stock status
     */
    setUpStockStatus: function setUpStockStatus() {
        if ($('.stock-status-4').length) {
            // Out of stock
            var stockStatusTitle = $('.product-media').data('stock-status-title');
            $('<div class="stock-status-info" />').append($('<span />', { text: stockStatusTitle })).appendTo($('.product-media > figure'));
        }
    },

    /**
     * Hide element when clicked outside
     */
    hideOnClickOutside: function hideOnClickOutside(selector, classToRemove) {
        $(document).mouseup(function (e) {
            var container = $(selector);
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.removeClass(classToRemove);
            }
        });
    },
    toggleOnClickOutside: function toggleOnClickOutside(selector, classesToToggle) {
        $(document).mouseup(function (e) {
            var container = $(selector);
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.toggleClass(classesToToggle);
            }
        });
    },

    /**
     * Replace image with fullsize image
     */
    origImgFile: function origImgFile(image) {
        return image.each(function (index, element) {
            var file = $(this).attr('src');
            if (file.search(/(_grande|_large|_small|_medium)/g)) {
                $(this).attr('src', file.replace(/(cache\/|_grande|_large|_small|_medium)/g, ''));
            }
        });
    },

    isInIframe: function isInIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }
};

/**
 * Trialshop theme-color-scheme
 */
function applyHeaderOptionsWhenInDesignMode() {
    if ($('html').hasClass('theme-color-scheme-5')) {
        sw.theme.options.siteHeader.fixedHeader.enabled = false;
        sw.theme.options.siteHeader.hybridHeader.isEnabled = true;

        $(window).on('resize', function () {
            var win = $(this);
            if (win.width() < 1200) {
                $('.search-block').addClass('search-block-with-fullwidth');
                sw.theme.setupSearch();
            } else {
                var $searchBlock = $('.search-block');
                $searchBlock.removeClass('search-block-with-fullwidth');
                $searchBlock.prependTo('.has-search-block');
                $(document).off('click', '.toggle-fullwidth-search');
                $('.toggle-fullwidth-search').detach();
                sw.theme.setupSearch();
            }
        }).resize();
    }
}

/**
 * RTL Settings
 */
function rtlContent() {
    var html = document.querySelector('html');

    if (html.dir === 'rtl') {
        sw.theme.options.slideshow.rightToLeft = true;
        sw.theme.options.productUpsellSlideshow.rightToLeft = true;
    }
}

$(document).ready(sw.theme.init);